import { Page } from '../functions/shared/page'

const BUILDER_API_KEY = '90306ddafe2748d3867139d36551b491'

export async function getBuilderPage(builderPageId: string): Promise<Page> {
  const { data: builderPage } = await fetch(
    `https://cdn.builder.io/api/v1/html/page?apiKey=${BUILDER_API_KEY}&url=/${builderPageId}`
  )
    .then((res) => res.json())
    .catch((e) => {
      console.error(e)
    })
  return {
    css: '',
    markdown: builderPage.html,
    title: builderPage.title.default,
    description: '',
    path: `builder-${builderPageId}`,
    tags: [],
    class: '',
  }
}
