import { xin, elements, vars } from 'xinjs'
import { contactForm } from './mocks/contact-form'
import { reviewForm } from './mocks/review-form'
import {
  inBox,
  markdownViewer,
  pageEditor,
  formViewer,
  complaintsView,
  crudTool,
  uploadTsv,
  serviceTest,
  loadingSpinner,
  statusReport,
  blogView,
  businessSearch,
  popPage,
} from './components'
import './components/contact-block'
import { collections } from './collections'
import { stripePayment } from './components/stripe-payment'
import {
  getRecord,
  getRecords,
  prefetched,
  service,
  logEvent,
  userAvailable,
} from './firebase'
import { app } from './app'
import { randomID } from './random-id'

const errorPage = (error: string, heading = 'Error') =>
  markdownViewer({ value: `# ${heading}\n\n${error}` })

const expiredLinkPage = () =>
  errorPage(
    `The link has expired for security reasons.

Please check your email for an updated link.

If you are not able to find it, please check your spam folder.`,
    'Link Expired'
  )

// url parser          http s? :   //  foo.co :1234?      / path     /.... ?....     #....
// groups              1    2                 3              4       5     6         7
const PATH_REGEXP =
  /^(http(s)?:)?\/\/[\w.-]+(:[\d]{1,5})?\/([\w-]+)(\/.*)?(\?[^#]+)?(#.*)?$/
const { div } = elements

export interface Route {
  title: string
  href: string
  alias?: RegExp
  content: () => Element
  hidden?: boolean
  admin?: boolean
  fullwidth?: boolean
  editableContent?: string
  forceReload?: boolean
  menuIcon?: string
}

const notFound = () =>
  markdownViewer(
    '## 404: Not Found\n\nSorry, the page you were looking for was not found.'
  )

const notFoundRoute: Route = {
  title: 'Not Found',
  href: '/notfound',
  hidden: true,
  content: notFound,
}

export const routes: Route[] = [
  {
    menuIcon: 'home',
    title: 'Home',
    href: '/',
    hidden: true,
    editableContent: 'home page',
    content() {
      return markdownViewer({ pageId: 'home' })
    },
  },
  notFoundRoute,
  {
    menuIcon: 'star',
    title: 'Reviews',
    href: '/reviews/',
    content() {
      // initTrustMary()
      document.head.append(
        elements.script({
          src: 'https://widget.trustmary.com/19pNILLUt',
          async: true,
        })
      )
      return div({
        style: {
          maxWidth: 'none',
        },
        dataTrustmaryWidget: '19pNILLUt',
      })
    },
  },
  {
    title: 'File a Complaint',
    hidden: true,
    href: '/complaint',
    alias: /-complaint/,
    editableContent: 'fac page',
    content() {
      const spinner = loadingSpinner()
      const context = {
        business: {
          name: 'unknown',
          price: 25,
        },
      }
      const [, path] = (window.location.pathname.match(/\/(.*)-complaint/) ||
        []) as string[]
      if (path) {
        const business = prefetched.records.find(
          (rec) => rec._collection === 'business-profile' && rec.path === path
        )
        if (business) {
          context.business = business
        } else {
          service.business.get({ path }).then((business) => {
            context.business = business
            spinner.replaceWith(markdownViewer({ pageId: 'fac', context }))
          })
          return spinner
        }
        return markdownViewer({ pageId: 'fac', context })
      } else {
        return businessSearch()
      }
    },
  },
  {
    title: 'Thank you!',
    href: '/thank-you',
    hidden: true,
    fullWidth: false,
    editableContent: 'thank-you page',
    content() {
      const [, id] = window.location.pathname.match(/thank-you\/(\w*)/) || []
      if (id) {
        return markdownViewer({ pageId: 'thank-you', context: { id } })
      } else {
        return notFound()
      }
    },
  },
  {
    menuIcon: 'search',
    title: 'Business Search',
    href: '/business',
    hidden: true,
    alias: /-customer-service/,
    fullwidth: false,
    editableContent: 'business page',
    content() {
      let context: Promise<any>
      if (currentPath.includes('-customer-service')) {
        const [, path] = currentPath.match(/([^/]*)-customer-service/) || []
        context = service.business
          .get({ path })
          .then((business) => ({ business }))
        return markdownViewer({ pageId: 'business', context })
      } else {
        return businessSearch()
      }
    },
  },
  {
    title: 'Complaints',
    hidden: true,
    href: '/complaints',
    editableContent: 'fac page',
    content() {
      const [, path] = currentPath.match(/^\/complaints\/([^/?]+)[/?]?/) || []
      const params = new URLSearchParams(window.location.search)
      const page = params.get('p') || 0
      return complaintsView({ path, page })
    },
  },
  {
    menuIcon: 'users',
    title: 'Team',
    hidden: false,
    href: '/team/',
    editableContent: 'team page',
    content() {
      return markdownViewer({ pageId: 'team' })
    },
  },
  {
    title: 'About Us',
    hidden: true,
    href: '/about/',
    editableContent: 'about page',
    content() {
      return markdownViewer({ pageId: 'about' })
    },
  },
  {
    title: 'Contact Us',
    hidden: true,
    href: '/contact',
    content() {
      return formViewer({
        form: contactForm,
        history: true,
        value: {},
        style: { marginBottom: vars.spacing200 },
      })
    },
  },
  {
    title: 'Terms & Conditions',
    hidden: true,
    href: '/terms',
    editableContent: 'terms page',
    content() {
      return markdownViewer({ pageId: 'terms' })
    },
  },
  {
    title: 'Privacy Policy',
    hidden: true,
    href: '/privacy',
    editableContent: 'privacy page',
    content() {
      return markdownViewer({ pageId: 'privacy' })
    },
  },
  {
    title: 'Sign In',
    hidden: true,
    href: '/admin',
    content() {
      if (!app.ui.isInternal) {
        app.ui.showSignin = true
        app.ui.showMenu = true
      }
      return div(
        { style: { textAlign: 'center', padding: vars.spacing200 } },
        'Use the menu to sign in (or out)'
      )
    },
  },
  {
    menuIcon: 'blog',
    title: 'Blog',
    hidden: false,
    href: '/blog/',
    content() {
      const [, , slug] = currentPath.split('/')
      return blogView({ slug })
    },
  },
  {
    title: 'Inbox',
    href: '/inbox',
    admin: true,
    content() {
      return inBox()
    },
  },
  {
    title: 'Page Editor',
    href: '/page-editor',
    hidden: true,
    admin: true,
    fullwidth: false,
    content() {
      const params = new URLSearchParams(window.location.search)
      const path = params.get('p') || ''
      const id = params.get('id') || ''
      const pageView = markdownViewer()
      return elements.fragment(
        pageView,
        pageEditor({ pageId: id, path, pageView })
      )
    },
  },
  {
    title: 'Stripe Payment',
    href: '/stripe-payment',
    hidden: true,
    admin: true,
    content() {
      return stripePayment()
    },
  },
  {
    title: 'Case',
    href: '/case',
    hidden: true,
    editableContent: 'case page',
    content() {
      const [, , caseId] = currentPath.split(/[/?]/)
      const params = new URLSearchParams(window.location.search)
      const [customerLink, businessLink, reviewLink] = [
        params.get('c'),
        params.get('b'),
        params.get('r'),
      ]
      if (customerLink !== null) {
        return errorPage(
          "Sorry, we haven't quite gotten this working yet",
          'Coming Soon!'
        )
      } else if (businessLink !== null) {
        return errorPage(
          "Sorry, we haven't quite gotten this working yet",
          'Coming Soon!'
        )
      } else if (reviewLink !== null) {
        const spinner = loadingSpinner('loading please wait…')
        service.case
          .get({ caseId, reviewLink })
          .then(async (response) => {
            if (response.linkExpired) {
              spinner.replaceWith(expiredLinkPage())
            } else if (response.error) {
              spinner.replaceWith(errorPage(response.error))
            } else {
              const caseData = response
              let existing: any
              let business: any
              try {
                ;[business, existing] = await Promise.all([
                  getRecord('business-profile', caseData.businessId),
                  getRecords('review', {
                    field: 'caseId',
                    operator: '==',
                    value: caseId,
                  }),
                ])
                if (existing.length === 0) {
                  existing = [{}]
                }
              } catch (_) {
                // fine then…
              }
              const contextId = randomID()
              xin[contextId] = { case: caseData, business }
              spinner.replaceWith(
                formViewer({
                  form: reviewForm,
                  value: {
                    caseId,
                    reviewLink,
                    ...existing[0],
                    _context: contextId,
                  },
                  style: { marginBottom: vars.spacing200 },
                })
              )
            }
          })
          .catch(() => {
            spinner.replaceWith(notFound())
          })
        return spinner
      } else {
        return markdownViewer({
          pageId: 'case',
          context: service.case.get({ caseId }).then(async (caseData) => {
            let review: any = null
            try {
              review = await service.review.get({ caseId: caseData._id })
            } catch (e) {
              // do nothing
            }
            return { case: caseData, review }
          }),
        })
      }
    },
  },
  {
    title: 'Business Profile Editor',
    href: '/business-profile-editor',
    editableContent: 'business-profile-editor page',
    admin: true,
    hidden: true,
    content() {
      const [, , businessId] = currentPath.split(/[/?]/)
      return markdownViewer({
        pageId: 'business-profile-editor',
        context: getRecord('business-profile', businessId).then((business) => {
          return { business }
        }),
      })
    },
  },
  {
    title: 'Upload TSV',
    href: '/upload',
    hidden: true,
    admin: true,
    content() {
      return uploadTsv({ style: { height: '100%' } })
    },
  },
  {
    title: 'Data Admin',
    href: '/data',
    admin: true,
    content() {
      return crudTool({ style: { height: '100%' }, collections })
    },
  },
  {
    title: 'Report',
    href: '/report',
    admin: true,
    content() {
      return statusReport()
    },
  },
  {
    title: 'Builder Page',
    href: '/b',
    admin: false,
    content() {
      const [, builderPageId] =
        window.location.pathname.match(/^\/b\/(.*)$/) || []
      return markdownViewer({ pageId: `builder-${builderPageId}` })
    },
  },
  {
    title: 'Service Test',
    href: '/service-test',
    admin: true,
    content() {
      return serviceTest({ style: { overflow: 'hidden', overflowY: 'auto' } })
    },
  },
].map((route: Route): Route => {
  if (route.admin === undefined) {
    route.admin = false
  }
  if (route.fullwidth === undefined) {
    route.fullwidth = route.admin
  }
  return route
})

export const publicRoutes = routes.filter(
  (route) => !route.hidden && !route.admin
)
export let currentPath = ''
export const route = loadRoute(
  getRoute(window.location.href),
  false,
  false,
  true
)

function getRoute(href = window.location.href): string {
  const [, , , , path, subpath, query] =
    href != null ? href.match(PATH_REGEXP) || [] : []
  let route = '/'
  if (typeof path === 'string') {
    route += path
    if (typeof subpath === 'string') {
      route += subpath.startsWith('/') ? subpath : `/${subpath}`
    }
    if (typeof query === 'string') {
      route += query
    }
  }
  return route
}

async function findRoute(testPath: string): Promise<Route> {
  const path = testPath.split('/')[1].split(/#|\?/)[0]

  let found = notFoundRoute
  for (const r of routes) {
    if (r.href.split('/')[1] !== path && r.alias?.test(path) !== true) {
      continue
    }

    if (r.admin === true && app.privileges?.isCSR !== true) {
      app.ui.showSignin = true
      app.ui.showMenu = true
      if (app.privileges === undefined) {
        break
      }
      await userAvailable()
      if (app.privileges?.isCSR) {
        found = r
        break
      }
    } else {
      found = r
      break
    }
  }

  return found
}

export async function loadRoute(
  path = '',
  popping = false,
  forceLoad = false,
  isInitialLoad = false
): Promise<Route> {
  const newRoute = findRoute(path)
  const route = await newRoute
  if (route.forceReload && !isInitialLoad) {
    window.location.reload()
  }
  const main = document.querySelector('main') as HTMLElement
  if (forceLoad || path !== currentPath) {
    logEvent('page_view', {
      page_title: route.title,
      page_path: route.href,
      page_location: path,
    })
    currentPath = path
    main.textContent = ''
    if (!popping) {
      window.history.pushState({ path }, '', path)
    }
    window.dispatchEvent(new Event('route-will-load'))
    main.append(route.content())
    main.scrollIntoView()
    xin.route = route
    app.ui.editableContent = route.editableContent ? route.editableContent : ''
    window.dispatchEvent(new Event('route-did-load'))
  }
  return newRoute
}

window.addEventListener('popstate', (/* event: PopStateEvent */): void => {
  loadRoute(getRoute(window.location.href), true)
})

window.document.addEventListener('click', (event: MouseEvent): void => {
  const a = (event.target as HTMLAnchorElement).closest('a')
  if (a == null) {
    return
  }
  const href = a.getAttribute('href') || ''
  if (href.match(/^\w+/)) {
    return
  } else if (href && !a.getAttribute('target')) {
    const path = getRoute(a.href)
    if (path != null) {
      event.stopPropagation()
      event.preventDefault()
      if (a.dataset.pop) {
        const context = a.closest('md-viewer')
          ? (a.closest('md-viewer') as any).context
          : {}
        document.body.append(
          popPage({
            pageId: a.dataset.pop,
            context,
          })
        )
        logEvent('page_view', {
          page_title: `popup ${a.dataset.pop}`,
          page_path: a.getAttribute('href'),
          page_location: a.href,
        })
      } else {
        loadRoute(path)
      }
    }
  }
})
