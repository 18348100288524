import {
  ElementCreator,
  Component as WebComponent,
  elements,
  vars,
} from 'xinjs'
import { icons } from 'xinjs-ui'
import { marked } from 'marked'
import { populateTemplate, lateBinding } from '../populate-template'
import { service, logEvent as _logEvent } from '../firebase'
import { loadingSpinner } from './loading-spinner'
import { EventNameString } from 'firebase/analytics'
import { getBuilderPage } from '../builder'
export class MarkdownViewer extends WebComponent {
  src = ''
  private _css = ''

  get css(): string {
    return this._css
  }

  set css(source: string) {
    this._css = source
    this.queueRender(true)
  }

  classes = [] as string[]
  private _value = ''
  context: any | Promise<any> | null = null

  get value(): string {
    return this._value
  }

  set value(newValue: string) {
    if (typeof newValue === 'string') {
      this._value = newValue
    }
    this.queueRender()
  }

  pageId = ''
  placeholder: ElementCreator | null = () =>
    elements.div(
      { style: { padding: vars.spacing } },
      loadingSpinner({ class: 'centered' })
    )

  constructor() {
    super()
    this.initAttributes('src', 'pageId', 'id', 'classes')
  }

  loadPage = async (
    pageId?: string,
    context?: any | Promise<any>
  ): Promise<void> => {
    if (pageId !== undefined) {
      this.pageId = pageId
      this.context = context || null
    }
    this.context =
      this.context instanceof Promise ? await this.context : this.context || {}
    if (this.pageId !== '') {
      try {
        const page = this.pageId.startsWith('builder-')
          ? await getBuilderPage(this.pageId.substring(8))
          : await service.record.get({ p: `page/path=${this.pageId}` })
        if (page) {
          this.classes = page.class ? page.class.split(' ') : []
          this.classes.forEach((className: string) =>
            this.classList.add(className)
          )
          this.css = page.css || ''
          this.value = page.markdown as string
        } else {
          if (this.value.trim() === '') {
            this.value = `## 404\n\nPage "${this.pageId}" Not Found`
          }
        }
      } catch (e) {
        console.error(`page ${this.pageId} failed to load with error`, e)
      }
    } else if (this.src !== '') {
      void (async () => {
        const request = await fetch(this.src)
        this.value = await request.text()
      })()
    } else if (this.value === '' && this.textContent != null) {
      this.value = this.textContent
    }
  }

  connectedCallback(): void {
    super.connectedCallback()
    this.loadPage()
  }

  get html(): string {
    if (this.value === null) {
      return ''
    }

    // 'foo <foo> bar <bar>' -> 'foo ', 'foo> bar ', 'bar>'
    let source = this.value
    if (this.context && !(this.context instanceof Promise)) {
      source = populateTemplate(source, this.context)
    }
    const chunks = source
      .split('\n')
      .reduce((chunks: string[], line: string) => {
        if (line.startsWith('<') || chunks.length === 0) {
          chunks.push(line)
        } else {
          const lastChunk = chunks[chunks.length - 1]
          if (!lastChunk.startsWith('<') || !lastChunk.endsWith('>')) {
            chunks[chunks.length - 1] += '\n' + line
          } else {
            chunks.push(line)
          }
        }
        return chunks
      }, [] as string[])
    const rendered = chunks
      .map((chunk) =>
        chunk.startsWith('<') && chunk.endsWith('>')
          ? chunk
          : marked(chunk, { mangle: false, headerIds: false })
      )
      .join('')

    return this.css !== '' ? `<style>${this.css}</style>${rendered}` : rendered
  }

  render(): void {
    super.render()

    if (this.value.trim() === '' && this.placeholder != null) {
      this.textContent = ''
      this.append(this.placeholder())
    } else {
      this.innerHTML = this.html
      lateBinding(this.context, this)
      const anchors = [...(this.querySelectorAll('a') || [])]
      anchors.forEach((a) => {
        const href = a.getAttribute('href') || ''
        if (href.match(TIKTOK_EMBED_REGEX)) {
          a.replaceWith(tikTok({ href }))
        } else if (
          a.closest('form, label, xin-form') ||
          (href || '').match(/^https?:\/\//)
        ) {
          a.setAttribute('target', '_blank')
        }
      })
    }
  }
}

export const markdownViewer = MarkdownViewer.elementCreator({
  tag: 'md-viewer',
  styleSpec: {
    ':host': {
      display: 'block',
      position: 'relative',
    },
  },
}) as ElementCreator<MarkdownViewer>

const TIKTOK_EMBED_REGEX = /https:\/\/www.tiktok.com\/@[^/]+\/video\//
export class TikTok extends WebComponent {
  href = ''
  embed: any = null
  width = '170px'
  height = '300px'

  constructor() {
    super()

    this.initAttributes('href')
  }

  async load() {
    const { href, width, height } = this
    if (!href) return

    if (!href.match(TIKTOK_EMBED_REGEX)) {
      console.error('TikTok bad url', href)
    }

    const response = await fetch('https://www.tiktok.com/oembed?url=' + href)
    const { thumbnail_url, title } = (this.embed = await response.json())
    this.append(
      elements.a(
        {
          href,
          target: '_blank',
          class: 'unadorned',
          title,
          style: {
            width,
            height,
          },
        },
        elements.img({ src: thumbnail_url }),
        icons.play({}),
        icons.tiktok({})
      )
    )
  }

  connectedCallback() {
    super.connectedCallback()

    this.load()
  }
}

export const tikTok = TikTok.elementCreator({
  tag: 'tik-tok',
  styleSpec: {
    ':host': {
      display: 'block',
      margin: 'auto',
      position: 'relative',
    },
    ':host a': {
      boxShadow: 'none !important',
      position: 'relative',
      borderRadius: '4px',
    },
    ':host a:hover': {
      outline: `${vars.primaryColor} solid 2px`,
    },
    ':host svg': {
      _fontSize: 24,
      position: 'absolute',
    },
    ':host .icon-play': {
      transition: '0.125s ease-in-out',
      top: '50%',
      left: '50%',
      _fontSize: 32,
      transform: 'translate(-50%, -50%) scale(1)',
      _textColor: '#fff8',
    },
    ':host:hover .icon-play': {
      _textColor: 'white',
      transform: 'translate(-50%, -50%) scale(1.25)',
    },
    ':host .icon-tiktok': {
      _textColor: 'white',
      bottom: vars.spacing50,
      right: vars.spacing50,
    },
    ':host img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },
})

export class DisplayDate extends WebComponent {
  value: Date | string = new Date()

  render() {
    super.render()

    if (typeof this.value === 'string') {
      this.value = new Date(this.value)
    }
    this.textContent = this.value.toLocaleDateString()
  }
}

export const displayDate = DisplayDate.elementCreator({ tag: 'display-date' })

export class HideNull extends WebComponent {
  value: any = false

  render() {
    super.render()

    const hidden =
      !this.value || ['null', 'false', 'undefined'].includes(this.value)
    this.toggleAttribute('hidden', hidden)
  }
}

export const hideNull = HideNull.elementCreator({ tag: 'hide-null' })

export class DisplayName extends WebComponent {
  firstName = ''
  lastName = ''

  constructor() {
    super()

    this.initAttributes('firstName', 'lastName')
  }

  render() {
    super.render()
    this.textContent = this.firstName + ' ' + this.lastName.substring(0, 1)
  }
}

export const displayName = DisplayName.elementCreator({ tag: 'display-name' })

export class ClearPeristedData extends WebComponent {
  persistId = ''

  constructor() {
    super()

    this.initAttributes('persistId')
  }

  render() {
    if (this.persistId) {
      localStorage.removeItem(this.persistId)
    }
  }
}

export const clearPersistedData = ClearPeristedData.elementCreator({
  tag: 'clear-persisted-data',
})

export class LogEvent extends WebComponent {
  type: EventNameString = 'page_view'

  payload: any = null
  payloadJson = 'false'

  content = null

  constructor() {
    super()

    this.initAttributes('type', 'payload', 'payloadJson')
  }

  connectedCallback() {
    super.connectedCallback()

    let payload = null
    try {
      payload = this.payload || JSON.parse(this.payloadJson || 'null')
    } catch (e) {
      console.warn('cannot log event, bad payload', e)
    }
    if (payload !== null) {
      _logEvent(this.type, payload)
    }
  }
}

export const logEvent = LogEvent.elementCreator({ tag: 'log-event' })
